import React from 'react';

import './privacy.scss';

const Privacy = () => {
  return (
    <section id='privacy'>
      <div className='section-title'>
        <h2>Datenschutz</h2>
      </div>
      <div className='container content'>
        <h3>Verantwortung und Ansprechpartner</h3>
        <p>
          Julian Naumann <br></br>
          <span className='secondary-text'>Mainstraße 80a</span> <br></br>
          <span className='secondary-text'>16321 Bernau bei Berlin</span> <br></br>
          <span className='secondary-text'>
            Bei Fragen: <a href='contact@devtory.io'>contact@devtory.io</a>
          </span>
        </p>
        <h3>Warum Datenschutz</h3>
        <p className='secondary-text'>
          Mit dieser Datenschutzerklärung wollen wir Sie über die Art, Umfang und Zweck der Erhebung und Verwendung von personenbezogenen
          durch uns informieren.
        </p>
        <p className='secondary-text'>
          Wir als Webseitenbetreiber nehmen den Datenschutz sehr ernst und behandeln Ihre personenbezogenen Daten vertraulich und nur nach
          den gesetzlichen Vorschriften. Wir empfehlen Ihnen unsere Datenschutzerklärung in regelmäßigen Abständen zu lesen, da sich diese,
          durch eine ständige Weiterentwicklung unserer Internetseite oder Angebote bzw. durch gesetzliche oder behördliche Vorgaben, auch
          ändern kann.
        </p>
        <p className='secondary-text'>
          In dieser Datenschutzerklärung nutzen wir Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”), die in Art. 4 der DS-GVO
          definiert sind.
        </p>
        <p className='secondary-text'>
          Sofern einzelne Formulierungen oder Teile dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht mehr vollständig
          entsprechen, bleiben die übrigen Teile dieser Erklärung in ihrem Inhalt und Ihrer Gültigkeit davon unberührt und rechtswirksam.
        </p>
        <h3>Welche Rechte Haben Sie?</h3>
        <h4>Recht auf Auskunft</h4>
        <span className='subtitle'>Art. 15 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, jederzeit unentgeltlich Auskunft über Ihre von uns gespeicherten personenbezogenen Daten zu erhalten.
        </p>
        <h4>Recht auf Berichtigung</h4>
        <span className='subtitle'>Art. 16 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht darauf, dass wir unrichtige personenbezogene Daten in unserem Datenbestand korrigieren.
        </p>
        <h4>Recht auf Löschung</h4>
        <span className='subtitle'>Art. 17 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, die Löschung Ihrer Daten zu verlangen. Diese werden wir unverzüglich Löschen, sollten nicht wirksame
          Rechtmäßigkeiten für die weitere Speicherung bestehen. Sofern nicht in den detaillierteren Datenschutzerklärungen abweichend
          geregelt, löschen wir Ihre personenbezogenen Daten, wenn das Vertragsverhältnis mit Ihnen beendet ist, sie von Ihrem Recht auf
          Löschung Gebrauch gemacht haben, sämtliche gegenseitige Ansprüche erfüllt sind und keine anderweitigen gesetzlichen
          Aufbewahrungspflichten oder gesetzliche Rechtfertigungsgrundlagen für die Speicherung bestehen.
        </p>
        <h4>Recht auf Einschränkung der Verarbeitung</h4>
        <span className='subtitle'>Art. 18 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 Abs. 1 DSGVO definierten
          Voraussetzungen gegeben ist.
        </p>
        <h4>Mitteilungspflicht</h4>
        <span className='subtitle'>Art. 19 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, im Falle des Wirksamwerdens von Art. 16, 17 oder 18 DSGVO über die getätigten Aktivitäten informiert zu
          werden.
        </p>
        <h4>Recht auf Datenübertragbarkeit</h4>
        <span className='subtitle'>Art. 20 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, die personenbezogenen Daten, die wir von Ihnen gespeichert haben, in einem strukturierten, gängigen Format zu
          erhalten.
        </p>
        <h4>Widerspruchsrecht</h4>
        <span className='subtitle'>Art. 21 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, jederzeit gegen die Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, insofern die Rechtmäßigkeit
          der Verarbeitung auf Art. 6 Abs. 1 lit. e, f beruht.
        </p>
        <h4>Automatisierte Entscheidungen</h4>
        <span className='subtitle'>Art. 22 DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, dass Entscheidungen nicht auf einer automatisierten Verarbeitung, einschließlich Profiling, getroffen werden.
        </p>
        <h4>Recht auf Widerruf</h4>
        <span className='subtitle'>Art. 13 Abs. 2 lit. c DSGVO</span>
        <p className='secondary-text'>
          Sie haben das Recht, jederzeit Ihre Einwilligung zur Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten für die
          Zukunft jederzeit zu widerrufen.
        </p>
        <h4>Recht auf Beschwerde</h4>
        <span className='subtitle'>Art. 77 DSGVO</span>
        <p className='secondary-text'>Sie haben das Recht, bei Ihrer zuständigen Aufsichtsbehörde ihres Wohnortes Beschwerde einzulegen.</p>
        <h3>Welche Daten verarbeiten wir?</h3>
        <h4>Sicherheit</h4>
        <p className='secondary-text'>
          Zur Gewährleistung einer gesicherten Verbindung, z.B. über das Kontaktformular, verwenden wir eine verschlüsselte Verbindung
          mittels SSL-Zertifikat.
        </p>
        <p className='secondary-text'>
          Die SSL-Verschlüsselung verhindert das Abfangen der übermittelten Daten und damit den Diebstahl dieser durch Dritte.
        </p>
        <p className='secondary-text'>
          Wir weisen Sie an dieser Stelle trotzdem darauf hin, dass die Benutzung des Internets allgemeine Gefahren birgt, auf die wir
          keinen Einfluss haben. Besonders. Besonders im E-Mail-Verkehr sind Ihre Daten ohne weitere Vorkehrungen nicht sicher und können
          unter Umständen von Dritten erfasst werden.
        </p>
        <h4>Server-Log Dateien</h4>
        <p className='secondary-text'>
          Bei jedem Zugriff auf das Internetangebot und bei jedem Abruf einer Datei werden Daten über diesen Vorgang in einer Protokolldatei
          gespeichert und verarbeitet, die Ihr Browser automatisch übermittelt. Auf Grund unseres berechtigten Interesses (siehe Art. 6 Abs.
          1 lit. f DSGVO) erheben wir folgende Daten über die Zugriffe auf unserer Webseite und speichern diese in den Log-Dateien des
          Servers:
        </p>
        <li>angefragte Seite</li>
        <li>Referrer URL</li>
        <li>Datum und Uhrzeit der Serveranfrage</li>
        <li>Status der Serverabfrage</li>
        <li>übertragende Datenmenge</li>
        <li>IP-Adresse</li>
        <li>User-Agent (Browser + Betriebssystem)</li>
        <p className='secondary-text'>
          Diese Log-Dateien werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die Speicherung der Daten erfolgt allein aus
          Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können und werden nicht mit anderen Datenquellen zusammengeführt.
          Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig geklärt
          ist.
        </p>
        <h4>Cookies</h4>
        <p className='secondary-text'>
          Diese Webseite verwendet Cookies, um die Benutzerfreundlichkeit, Funktionsvielfalt und Sicherheit der Webseite zu erhöhen. Bei
          Cookies handelt es sich um kleine Dateien, welche auf Ihrem Endgerät gespeichert werden. Ihr Browser greift auf diese Dateien zu.
          Dies ermöglicht den individuellen Browser von anderen zu unterscheiden. So kann die Webseite durch den Einsatz von Cookies
          Funktionen anbieten, die ohne Cookies nicht möglich wären.
        </p>
        <p className='secondary-text'>
          Moderne Browser bieten die Möglichkeit Cookies nicht zuzulassen und diese somit generell für eine oder alle Webseiten zu
          deaktivieren. Bei Nutzung dieser Browserfunktion ist nicht gewährleistet, dass alle Funktionen dieser Webseite ohne
          Einschränkungen genutzt werden können.
        </p>
        <h4>Bei Anfrage zu Betroffenenrechten</h4>
        <p className='secondary-text'>
          Zweck der Verarbeitung: Um Ihnen Auskunft Ihre von uns gespeicherten personenbezogenen Daten geben zu können, verarbeiten wir die
          notwendigen personenbezogenen Daten.
        </p>
        <p className='secondary-text'>
          Weitergabe der personenbezogenen Daten: Die personenbezogenen Daten werden firmenintern durch den Datenschutzbeauftragten
          verarbeitet. Eine Weitergabe Ihrer Daten an Dritte ist ausgeschlossen.
        </p>
        <p className='secondary-text'>
          Dauer der Speicherung: Ihre personenbezogenen Daten werden angelehnt an rechtliche Anforderungen gespeichert. Nach Ablauf dieser
          und dem Zweckfortfall sonstiger Rechtsgrundlagen zur Verarbeitung werden Ihre Daten gelöscht.
        </p>
        <h4>Google Analytics</h4>
        <p className='secondary-text'>
          Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren. Die daraus gewonnenen Daten werden genutzt, um unsere
          Website sowie Werbemaßnahmen zu optimieren.
        </p>
        <p className='secondary-text'>
          Google Analytics ist ein Webanalysedienst, der von Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, United States)
          betrieben und bereitgestellt wird. Google verarbeitet die Daten zur Website-Nutzung in unserem Auftrag und verpflichtet sich
          vertraglich zu Maßnahmen, um die Vertraulichkeit der verarbeiteten Daten zu gewährleisten.
        </p>
        <p className='secondary-text'>Während Ihres Website-Besuchs werden u.a. folgende Daten aufgezeichnet:</p>
        <li>Aufgerufene Seiten</li>
        <li>Ihr Verhalten auf den Seiten (beispielsweise Klicks, Scroll-Verhalten und Verweildauer)</li>
        <li>Ihr ungefährer Standort (Land und Stadt)</li>
        <li>Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige Zuordnung möglich ist)</li>
        <li>Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung</li>
        <li>Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel Sie zu uns gekommen sind)</li>
        <li>Diese Daten werden an einen Server von Google in den USA übertragen. Google beachtet dabei die</li>
        <li>Datenschutzbestimmungen des „EU-US Privacy Shield“-Abkommens.</li>
        <p className='secondary-text'>
          Google Analytics speichert Cookies in Ihrem Webbrowser für die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese Cookies
          enthaltene eine zufallsgenerierte User-ID, mit der Sie bei zukünftigen Website-Besuchen wiedererkannt werden können.
        </p>
        <p className='secondary-text'>
          Die aufgezeichneten Daten werden zusammen mit der zufallsgenerierten User-ID gespeichert, was die Auswertung pseudonymer
          Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden automatisch nach 14 Monaten gelöscht. Sonstige Daten bleiben in
          aggregierter Form unbefristet gespeichert.
        </p>
      </div>
    </section>
  );
};

export default Privacy;
