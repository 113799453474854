import * as React from 'react';
import { Layout } from '../components/layout';
import Seo from '../components/utils/seo';
import Privacy from '../components/sections/privacy';

// markup
const Datenschutz = () => {
  return (
    <Layout secondaryMode={true}>
      <Seo title='Datenschutz'></Seo>
      <Privacy></Privacy>
    </Layout>
  );
};

export default Datenschutz;
